import React, { useState, useEffect } from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/custom-theme.scss";
import NavBar from "./components/NavBar";
import Banner from "./components/Banner";
import Memberships from "./components/memberships/Memberships";
import Coaches from "./components/Coaches";
import Footer from "./components/Footer";
import Pt from "./components/Pt";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import OurStory from "./components/OurStory";
import NotFound from "./components/NotFound";
import Merch from "./components/Merch";
import { useCookies } from "react-cookie";
import CookieAlert from "./components/CookieAlert";

function App() {
  const [loading, setLoading] = useState(false);
  const [cookies, setCookies] = useCookies(["cookieConsent"]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      <div className={`site-loader ${loading ? "" : "hidden"}`}>
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" exact element={<Banner />} />
            <Route path="/private-training" exact element={<Pt />} />
            <Route path="/memberships" element={<Memberships />} />
            <Route path="/merch" element={<Merch />} />
            <Route path="/coaches" element={<Coaches />} />
            <Route path="/our-story" element={<OurStory />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
          {!cookies.cookieConsent && <CookieAlert setCookies={setCookies} />}
        </Router>
      </div>
    </div>
  );
}

export default App;
