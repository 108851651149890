import yes from "../assets/svgs/check-circle-fill-yes.svg";
import pushing from "../assets/pushing.png";
import asterisk from "../assets/svgs/asterisk.svg";
import placeholder from "../assets/svgs/check-circle-placeholder.svg";

export const details = [
  {
    title: "Golden Gloves",
    points: [
      { text: "Unlimited Classes", symbol: yes, color: "black" },
      {
        text: "Open Gym Access",
        symbol: yes,
        color: "black",
      },
      {
        text: "1 Class Guests monthly",
        symbol: yes,
        color: "black",
      },
      {
        text: " 10% discount on private training",
        symbol: yes,
        color: "black",
      },
    ],
    img: pushing,
    // price: 214.70,
    price: 190,
    type: "monthly",
    link: "https://app.acuityscheduling.com/catalog.php?owner=26831882&action=addCart&id=1887318&clear=1",
  },
  {
    title: "Silver Gloves",
    points: [
      {
        text: "10 Classes",
        symbol: yes,
        color: "black",
      },
      {
        text: "1 Class Guests monthly",
        symbol: yes,
        color: "black",
      },
      {
        text: "",
        symbol: placeholder,
        color: "black",
      },
      {
        text: "",
        symbol: placeholder,
        color: "black",
      },
    ],
    img: pushing,
    // price: 192.1,
    price: 170,
    type: "monthly",
    link: "https://app.acuityscheduling.com/catalog.php?owner=26831882&action=addCart&id=1887331&clear=1",
  },
  {
    title: "Bronze Gloves",
    points: [
      {
        text: "8 classes",
        symbol: yes,
        color: "black",
      },
      {
        text: "$30 off First Month",
        symbol: asterisk,
        color: "black",
      },
      {
        text: `Coupon Code = FIRST-FREE`,
        symbol: asterisk,
        color: "black",
      },
      {
        text: "",
        symbol: placeholder,
        color: "black",
      },
    ],
    img: pushing,
    // price: 169.5,
    price: 150,
    type: "monthly",
    link: "https://app.acuityscheduling.com/catalog.php?owner=26831882&action=addCart&id=1887335&clear=1",
  },
  {
    title: "Junior Gloves Up",
    points: [
      {
        text: " 2 classes week",
        symbol: yes,
        color: "black",
      },
      {
        text: "12 to 17 years old",
        symbol: asterisk,
        color: "black",
      },
      {
        text: "",
        symbol: placeholder,
        color: "black",
      },
      {
        text: "",
        symbol: placeholder,
        color: "black",
      },
    ],
    img: pushing,
    // price: 180.8,
    price: 160,
    type: "monthly",
    link: "https://app.acuityscheduling.com/catalog.php?owner=26831882&action=addCart&id=1887339&clear=1",
  },
  {
    title: "Class Package",
    points: [
      {
        text: `10 classes \n Use it at your rhythm \n Expires in 2 months`,
        symbol: "",
        color: "black",
      },
    ],
    img: pushing,
    // price: 248.6,
    price: 220,
    type: "open",
    link: "https://app.acuityscheduling.com/catalog.php?owner=26831882&action=addCart&id=1887341&clear=1",
  },
  {
    title: "Jump In",
    points: [
      {
        text: "\u00A0\n 1 class \n \u00A0",
        symbol: yes,
        color: "black",
      },
    ],
    img: pushing,
    // price: 28.25,
    price: 25,
    type: "open",
    link: "https://app.acuityscheduling.com/catalog.php?owner=26831882&action=addCart&id=1887343&clear=1",
  },
  {
    title: "Personal Training",
    points: [
      {
        text: "1 on 1 for 1 hr",
        symbol: yes,
        color: "black",
      },
    ],
    img: pushing,
    // price: 113.00,
    price: 100,
    type: "tailorMade",
    link: "https://app.acuityscheduling.com/catalog.php?owner=26831882&action=addCart&id=1887344&clear=1",
  },
  // {
  //   title: "Semi Private",
  //   points: [
  //     {
  //       text: "2 on 1 for 1 hr",
  //       symbol: yes,
  //       color: "black",
  //     },
  //   ],
  //   img: pushing,
  //   price: 124.3,
  //   type: "tailorMade",
  //   link: "https://app.acuityscheduling.com/catalog.php?owner=26831882&action=addCart&id=1887346&clear=1",
  // },
  {
    title: "Training Package A",
    points: [
      {
        text: "5 Personal Training Sessions",
        symbol: yes,
        color: "black",
      },
    ],
    img: pushing,
    // price: 536.75,
    price: 475,
    type: "tailorMade",
    link: "https://app.acuityscheduling.com/catalog.php?owner=26831882&action=addCart&id=1887350&clear=1",
  },
  {
    title: "Training Package B",
    points: [
      {
        text: "10 Personal Training Sessions",
        symbol: yes,
        color: "black",
      },
    ],
    img: pushing,
    // price: 1017.0,
    price: 900,
    type: "tailorMade",
    link: "https://app.acuityscheduling.com/catalog.php?owner=26831882&action=addCart&id=1887351&clear=1",
  },
  {
    title: "Private Group Session",
    points: [
      {
        text: "3 to 6 Boxers",
        symbol: yes,
        color: "black",
      },
    ],
    img: pushing,
    price: "Please Contact Directly",
    type: "tailorMade",
    link: "",
  },
  {
    title: "Photo/Movie Projects",
    points: [
      {
        text: "\u00A0",
      },
    ],
    img: pushing,
    price: "Please Contact Directly",
    type: "tailorMade",
    link: "",
  },
];
