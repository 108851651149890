import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
// import Classes from "./Classes";
import Classes from "./Classes";
import bgImage1 from "../assets/gloves-up-sit-2.png";
import bgImage2 from "../assets/training.png";
import bgImage3 from "../assets/pushing.png";

export default function Banner() {
  const bgImages = [bgImage1, bgImage2, bgImage3];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % bgImages.length);
        setFade(true);
      }, 1000);
    }, 6000);

    return () => clearInterval(interval);
  }, [bgImages.length]);

  return (
    <>
      <div
        className={`banner ${fade ? "fade-in" : "fade-out"}`}
        style={{
          backgroundImage: `url(${bgImages[currentImageIndex]})`,
        }}
        id="home"
      >
        <Row className="align-items-center">
          <Col xs={0} sm={6} md={8} lg={8}></Col>
          <Col xs={12} sm={5} md={3} lg={2}>
            <div className="title">Gloves Up Boxing</div>
          </Col>
          <Col xs={0} sm={0} md={0} lg={2}></Col>
        </Row>
      </div>
      <div id="classes-nav">
        <Classes />
      </div>
    </>
  );
}
