import React from "react";
import { Row, Col } from "react-bootstrap";
import insta from "../assets/svgs/instagram.svg";
import { details } from "../../src/data/coachesDetails";

export default function Coach() {
  function onClick(item) {
    if (item === "Andrea Osterman") {
      window.open("https://glovesup.as.me/?calendarID=11242502", "_blank");
    }
    if (item === "Mickael Alban") {
      window.open(
        "https://glovesup.as.me/schedule/99e1705c/appointment/35680597/calendar/7085881?appointmentTypeIds%5B%5D=35680597",
        "_blank"
      );
    }
    if (item === "Stefanie Folgado") {
      window.open(
        "https://glovesup.as.me/?calendarID=11306062",
        "_blank"
      );
    }
  }
  return (
    <Row className="coach-row">
      {details.map((item, index) => (
        <Col
          xxl={6}
          xl={6}
          lg={12}
          className="d-flex justify-content-center coach-parent"
          key={index}
        >
          <div className="card coach">
            <Col md={6} className="d-flex justify-content-center">
              <img
                src={item.img}
                className="card-img-top coach-img"
                alt="..."
              />
            </Col>
            <Col md={6}>
              <div className="card-body coach-body">
                <h5 clasNameclassNames="card-title coach-title">{item.name}</h5>
                <p className="card-text coach-text">{item.desc}</p>
                <div className="coach-contact">
                  <div className="coach-insta">
                    <img src={insta} className="coach-insta-img" />
                    <a href={item.insta}>Instagram</a>
                  </div>
                  {item.name === "Mickael Alban" ||
                  item.name === "Andrea Osterman" ||
                  item.name === "Stefanie Folgado" ? (
                    <a
                      className="btn btn-primary"
                      onClick={() => onClick(item.name)}
                    >
                      Book a Session
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Col>
          </div>
        </Col>
      ))}
    </Row>
  );
}
