import React, { useEffect, useState } from "react";
import Headers from "../Headers";
import { Container, Row } from "react-bootstrap";
import { details } from "../../data/membershipDetails";
import "./memberships.css";
import Carousel from "./Carousel";
import Accordion from "./Accordion";

export default function Memberships() {
  const [smallScreen, setSmallScreen] = useState(true);
  const [counterValue, setCounterValue] = useState(3);

  // Helper function to process membership slides into groups of 3
  function processSlides(input) {
    let newArr = []; // Temporary array to hold a group of 3 items
    let groupedSlides = []; // Array to hold all groups of 3
    let counter = 0; // Counter to track the number of items in a group

    // Loop through the input to group items
    for (let i = 0; i < input.length; i++) {
      let element = input[i]; // Current item
      newArr.push(element); // Add the current item to the group
      counter++;

      // If the group is full (3 items) or it's the last item
      if (counter === counterValue || i === input.length - 1) {
        // Add placeholder items if the group has less than 3 items
        while (newArr.length < counterValue) {
          newArr.push({ type: "placeholder" }); // Placeholder objects to keep groups consistent
        }
        groupedSlides.push(newArr); // Push the group into the array
        newArr = []; // Reset the group for the next set of items
        counter = 0; // Reset the counter for the next group
      }
    }

    return groupedSlides; // Return the final array of grouped slides
  }

  // Separate the slides by membership type using the processSlides function
  const monthlySlides = processSlides(
    details.filter((item) => item.type === "monthly") // Filter for monthly memberships
  );
  const openSlides = processSlides(
    details.filter((item) => item.type === "open") // Filter for open memberships
  );
  const tailorMadeSlides = processSlides(
    details.filter((item) => item.type === "tailorMade") // Filter for tailor-made memberships
  );

  const accordItems = processAccord(
    details.filter((item) => item.type === "monthly"),
    details.filter((item) => item.type === "open"),
    details.filter((item) => item.type === "tailorMade")
  );

  const monthlyAccord = processAccord(
    details.filter((item) => item.type === "monthly"),
    details.filter((item) => item.type === "open"),
    details.filter((item) => item.type === "tailorMade")
  );

  function processAccord(monthly, open, tailorMade) {
    const groupedArr = [
      { title: "Monthly Memberships", items: [...monthly] },
      { title: "One Offs", items: [...open] },
      { title: "Tailor Made", items: [...tailorMade] },
    ];
    return groupedArr;
  }

  useEffect(() => {
    const handleLayout = () => {
      setSmallScreen(window.innerWidth < 992);
      setCounterValue(window.innerWidth < 1400 ? 2 : 3);
    };

    // if resize then add event listener
    window.addEventListener("resize", handleLayout);

    // runs on load
    handleLayout();

    // clean up off event listener
    return () => {
      window.removeEventListener("resize", handleLayout);
    };
  }, []);

  return (
    <div className="memberships" id="home">
      <Headers title="Memberships" />
      <div className="memberships-body">
        <Container className="d-flex justify-content-center">
          <Row className="d-flex justify-content-center">
            {smallScreen ? (
              <>
                <Accordion details={accordItems} />
              </>
            ) : (
              <>
                <Carousel slides={monthlySlides} title="Monthly Memberships" />
                <Carousel slides={openSlides} title="One-Offs" />
                <Carousel slides={tailorMadeSlides} title="Tailor Made" />
              </>
            )}
          </Row>
        </Container>
        <div style={{marginLeft: "10px", fontSize: "12px"}}>*prices do not include tax</div>
      </div>
    </div>
  );
}
