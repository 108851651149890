import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import { Navbar, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import list from "../assets/svgs/list.svg";
import logo from "../assets/gloves-up-logo.png";
import x from "../assets/svgs/x.svg";
import arrow from "../assets/svgs/arrow-right-short.svg";

function NavBar() {
  const [activeLink, setActiveLink] = useState("home");
  const [show, setShow] = useState(false);
  const [smallMenu, setSmallMenu] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const handleResizeOrScroll = () => {
      setSmallMenu(window.innerWidth < 992 || window.scrollY > 100);
    };

    // if resize or scroll then add event listener
    window.addEventListener("resize", handleResizeOrScroll);
    window.addEventListener("scroll", handleResizeOrScroll);
  
    // runs on load
    handleResizeOrScroll();
  
    return () => {
      window.removeEventListener("resize", handleResizeOrScroll);
      window.removeEventListener("scroll", handleResizeOrScroll);
    };
  }, []); 

  function updateActiveLink(value) {
    setActiveLink(value);
  }

  const handleClassesClick = (e) => {
    e.preventDefault();
    const isHomeRoute = window.location.pathname === "/";

    if (isHomeRoute) {
      const element = document.getElementById("classes-nav");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/", { replace: true });
      setTimeout(() => {
        const element = document.getElementById("classes-nav");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }

    updateActiveLink("classes-nav");
    setShow(false);
  };

  const resources = [
    {
      href: "#",
      name: "Classes",
      function: (e) => handleClassesClick(e),
    },
    {
      href: "/private-training",
      name: "Private Training",
      function: () => updateActiveLink("private-training"),
    },
    {
      href: "/coaches",
      name: "Coaches",
      function: () => updateActiveLink("coaches"),
    },
    {
      href: "/our-story",
      name: "Our Story",
      function: () => updateActiveLink("our-story"),
    },
    {
      href: "/Memberships",
      name: "Memberships",
      function: () => updateActiveLink("our-story"),
    },
    {
      href: "/Merch",
      name: "Merch",
      function: () => updateActiveLink("our-story"),
    },
  ];

  return (
    <>
      <Navbar
        expand={smallMenu ? "xs" : "lg"}
        className={smallMenu ? "scrolled" : ""}
      >
        <Container>
          <Navbar.Brand
            href="/"
            className={smallMenu ? "nav-brand-small" : "nav-brand"}
          >
            <img src={logo} alt="Logo" className="nav-brand-small" />
          </Navbar.Brand>
          {smallMenu ? (
            <button className="btn btn-secondary" onClick={handleShow}>
              <img src={list} style={{ height: "20px" }} />
            </button>
          ) : (
            <Nav>
              {resources.map((item, index) => (
                <Nav.Link
                  href={item.href}
                  key={index}
                  className="navbar-link"
                  onClick={item.function}
                >
                  {item.name}
                </Nav.Link>
              ))}
            </Nav>
          )}
          <Modal className="modal" show={show} onHide={handleClose}>
            <div className="modal-parent" onClick={handleClose}>
              <img src={x} className="modal-cancel-btn" />
            </div>
            <Modal.Body style={{ border: "none" }}>
              {resources.map((item, index) => (
                <Nav.Link
                  href={item.href}
                  key={index}
                  className="navbar-link-small"
                  onClick={item.function}
                >
                  <div className="navbar-name">{item.name}</div>
                  <img src={arrow} className="navbar-arrow" />
                </Nav.Link>
              ))}
            </Modal.Body>
          </Modal>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
