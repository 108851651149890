import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Headers from "./Headers";

export default function Pt() {
  const [hover, setHover] = useState(false);


  useEffect(() => {
    const glowTextElements = document.querySelectorAll(".glow-text");  // Select all elements with class 'glow-text'

    const handleMouseEnter = () => {
      setHover(true);  // Set hover to true when any .glow-text is hovered
    };

    const handleMouseLeave = () => {
      setHover(false); // Set hover to false when mouse leaves
    };

    // Add event listeners to each glow-text element
    glowTextElements.forEach((glowText) => {
      glowText.addEventListener("mouseenter", handleMouseEnter);
      glowText.addEventListener("mouseleave", handleMouseLeave);
    });

    // Cleanup event listeners when component unmounts
    return () => {
      glowTextElements.forEach((glowText) => {
        glowText.removeEventListener("mouseenter", handleMouseEnter);
        glowText.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);


  return (
    <div className="pt">
      <Headers title="Private Training" />
      <div className="classes-parent row">
        <div className="classes-child col-12 col-lg-3 col-xl-2 d-flex flex-column">
          <span>Already Registered?</span>
          <span>Click &nbsp;<span className="glow-text" id="glowText">Login</span>&nbsp;below</span>
        </div>
        <div className="classes-child col-12 col-lg-3 col-xl-2 d-flex flex-column">
          <span>New Here?</span>
          <span>Click &nbsp;<span className="glow-text" id="glowText">Sign Up</span>&nbsp;below</span>
        </div>
        <div className="classes-child col-12 col-lg-2 col-xl-2 d-flex flex-column">
          <span>Questions?</span>
          <span>Read our &nbsp;<a className="guide-link" href="/Gloves-Up-How-To-Guide.pdf" target="_blank" rel="noopener noreferrer">Guide</a></span>
        </div>
      </div>
      <div className="pt-body d-flex justify-content-center">
        <Container className="d-flex flex-column justify-content-center">
          <div className="classes-note">
            <div>
              <span>Prices below include tax</span>
            </div>
            <div className="arrow-parent">
              <span className={`class-arrow ${hover ? "hover" : ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="40" fill="currentColor" class="bi bi-arrow-down-right-circle-fill" viewBox="0 0 20 20">
                  <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m5.904-2.803a.5.5 0 1 0-.707.707L9.293 10H6.525a.5.5 0 0 0 0 1H10.5a.5.5 0 0 0 .5-.5V6.525a.5.5 0 0 0-1 0v2.768z" />
                </svg>
              </span>
            </div>
          </div>
          <iframe src="https://app.acuityscheduling.com/schedule.php?owner=26831882&appointmentType=35680597&ref=embedded_csp" title="Schedule Appointment" width="100%" height="800" frameBorder="0"></iframe><script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
        </Container>
      </div>
    </div>
  )
}
