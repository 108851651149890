import mick from "../assets/mick-punch-3.png";
import mauricio from "../assets/Mauricio_Yin_Viera_profile_pic.png";
import caroline from "../assets/caroline.png";
import andrea from "../assets/andrea.png";
import stef from "../assets/stef.png"

export const details = [
  {
    name: "Mickael Alban",
    img: mick,
    desc: "Mickael, a skilled and patient coach, founded Gloves Up Boxing in 2016. He creates a supportive environment where technique and personal growth are paramount.",
    insta: "https://www.instagram.com/gloves_up_boxing/",
  },
  {
    name: "Mauricio Yin Viera",
    img: mauricio,
    desc: "Passionate about technique, Mauricio is calm and attentive to detail. Practicing Boxing, Muay Thai, Kickboxing, Jiu-Jitsu (brown belt), and Wrestling, you will always learn something in his classes.",
    insta: "https://www.instagram.com/myv_china/?igsh=dzFhbnRzdXFiZHB3",
  },
  {
    name: "Andrea Osterman",
    img: andrea,
    desc: "Andrea’s boxing journey began ten years ago in classes with Yvette Raposo.  She has been training with Mickael for many years and has been a devoted member of Gloves Up Boxing since 2022.  Outside of the gym, there’s a good chance she’s absorbed in an English or French novel, or savouring chocolate with Earl Grey tea.",
    insta:
      "https://www.instagram.com/andrea.osterman?igsh=MWR1aTB0NmZkbGx4YQ==",
  },
  {
    name: "Caroline Aksich",
    img: caroline,
    desc: "Meet Caroline, your high-energy fitness coach! With over two decades of playing rugby at Ontario's highest level and one memorable bout in the boxing ring—just to check it off her bucket list. Expect her classes to be dynamic and invigorating, challenging you to reach your personal best while having fun along the way.",
    insta:
      "https://www.instagram.com/caroline_jezibaba?igsh=MWNnZXVscmFxMW96bw==",
  },
  {
    name: "Stefanie Folgado",
    img: stef,
    desc: "With nearly two decades of training in multi-disciplinary combative sports including  Muay Thai and Boxing, Stef brings a wealth of knowledge and passion with her. Certified through Boxing Canada's National Coaching Certification Program (NCCP), she is dedicated to helping people of all levels master the art of boxing.",
    insta:
      "https://www.instagram.com/coach_stefff/profilecard/?igsh=MW9tNG14Z3lvNDhkNA==",
  },
];
